<template>
  <v-container fluid>
    <v-row>
      <v-col class="col-12 pt-0 pb-2">
        <h1>Link survey to quotation: #{{ quotation.jobNumber }}</h1>
        <p v-if="surveys.length === 0">First enter a customer name to find all surveys belonging to this customer.</p>
      </v-col>
    </v-row>
    <v-row v-if="surveys.length === 0">
      <v-col class="col-12 pt-0 pb-2">
        <v-form @submit.prevent="getSurveys">
          <v-row class="pt-8">
            <v-col>
              <label>Customer Name</label>
              <v-text-field v-model="customerName"></v-text-field>
            </v-col>
            <v-col>
              <v-btn @click="getSurveys">Find surveys</v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
    <v-row v-if="surveys.length > 0">
      <v-col class="col-12 pt-0 pb-2">
        <p>Select a survey to link it to this quotation</p>
        <v-simple-table>
          <tbody>
            <tr v-for="(survey, index) in surveys" :key="index">
              <td>{{ survey.date | prettyDate }}</td>
              <td>{{ survey.address }}</td>
              <td>
                <v-btn @click="linkSurvey(index)" small>Select</v-btn>
              </td>
            </tr>
          </tbody>
        </v-simple-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'QuotationsLinkSurvey',
  computed: {
    token() {
      return this.$store.state.token;
    },
    userid() {
      return this.$store.state.userid;
    },
  },
  data() {
    return {
      customerName: '',
      quotation: {
        contact_id: 0,
        postCode:'',
        purchase_order: '',
        purchase_order_uri: '',
      },
      surveys: [],
      message: '',
      showMessage: false,
    };
  },
  methods: {
    getQuotation() {
      axios.get(`/quotations/getSingle/${this.$route.params.id}.json?token=${this.token}`)
      .then((response) => {
        this.quotation = response.data;
      });
    },
    getSurveys() {
      const postData = {
        name: this.customerName,
      };
      axios.post(`/siteSurveys/findByCustomerName.json?token=${this.token}`, postData)
      .then((response) => {
        this.surveys = response.data.surveys;
      });
    },
    linkSurvey(index) {
      const surveyId = this.surveys[index].id;
      axios.get(`/quotations/linkSurvey/${this.$route.params.id}/${surveyId}.json?token=${this.token}`)
        .then(() => {
          this.$router.push(`/quotations/view-survey/${this.$route.params.id}`);
        });
    }
  },
  mounted() {
    this.getQuotation();
  },
};
</script>
